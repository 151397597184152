<script>
import postPasswordReset from '@/api/persons/postPasswordReset';

export default {
  name: 'AdminPasswordReset',
  data: function () {
    return {
      email: '',
      loading: false,
      successful: false,
      error: false,
    };
  },
  methods: {
    resetPassword: async function () {
      this.successful = false;
      this.error = false;
      this.loading = true;
      await postPasswordReset({ email: this.email }).catch(() => {
        this.error = true;
      });

      this.successful = true;
      this.loading = false;
    },
  },
};
</script>

<template>
  <div class="card">
    <div class="card-header bg-light">
      <h6 class="card-title">Password Reset</h6>
    </div>

    <div class="card-body">
      <p>
        This form grants the ability to reset a users password by providing their email address, upon submitting the
        form a password will be automatically generated and emailed to the user allowing them to log in.
      </p>
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group row mt-2">
            <label class="col-form-label col-lg-2" for="emailAddress">Email address</label>
            <div class="col-lg-4">
              <input id="emailAddress" class="form-control" v-model="email" v-on:keyup.enter="resetPassword" />
            </div>
            <div class="col-lg-4">
              <button class="btn btn-primary" @click="resetPassword" :disabled="email === '' || loading">
                Reset Password
                <i v-if="loading" class="icon-spinner11 ml-2 spinner"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="successful || error">
        <div class="col">
          <p class="bg-green p-2" v-if="successful">Password reset email successfully sent.</p>
          <p class="bg-warning p-2" v-if="error">Error sending password reset email.</p>
        </div>
      </div>
    </div>
  </div>
</template>
